import React, { useState, useEffect } from 'react';
import image1 from './guywithgun.jpg';
import image2 from './soyboy.png';
import image3 from './weird image 1.png';

const ImagesDisplay = () => {
  const images = [image1, image2, image3];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 1000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="image-display">
      <img 
        src={images[currentImage]} 
        alt={`Image ${currentImage + 1}`} 
        className="flashing-image"
      />
    </div>
  );
}

export default ImagesDisplay;