import React from 'react';
const VideoBackground = ({ src, isMuted }) => {
 return (
   <div className="video-container">
     <video
       autoPlay
       loop
       muted={isMuted}
       playsInline
       className="background-video"
     >
       <source src={src} type="video/mp4" />
     </video>
   </div>
 );
};
export default VideoBackground;