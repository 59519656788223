import React from 'react';

const Header = () => {
  return (
    <nav className="nav-bar">
      <a href="/" className="nav-link"></a>
      <a 
        href="https://www.dexscreener.com" 
        target="_blank" 
        rel="noopener noreferrer"
        className="nav-link buy-button"
      >
        Buy $MMM
      </a>
    </nav>
  );
}

export default Header;