import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './Header';
import ImagesDisplay from './ImageDisplay';
import VideoBackground from './VideoBackground';
import videoSource from './video.mp4';

const App = () => {
  const [isMuted, setIsMuted] = useState(true);

  const handleCopy = () => {
    navigator.clipboard.writeText("CA: XXXXXXXXXXXXXXXXXXXXXXXX");
    alert("Copied to clipboard!");
  };

  return (
    <div className="App">
      <VideoBackground src={videoSource} isMuted={isMuted} />
      <Header />
      <button 
        onClick={() => setIsMuted(!isMuted)} 
        className="toggle-audio-button"
      >
        {isMuted ? "Unmute" : "Mute"}
      </button>
      <ImagesDisplay />
      <div className="copy-text" onClick={handleCopy}>
        CA: XXXXXXXXXXXXXXXXXXXXXXXX
      </div>
    </div>
  );
}

export default App;